

export default {
  ADD_VehiclesType(state, item) {
    debugger;
    state.VehiclesType.unshift(item);
  },

  SET_VehiclesType(state, VehiclesType) {
    debugger;
    state.VehiclesTypes = VehiclesType;
  },

  SET_ALLVehiclesType(state, allVehiclesType) {
    state.AllVehiclesTypeS = allVehiclesType;
  },

  REMOVE_VehiclesType(state, itemId) {
    const ItemIndex = state.VehiclesType.findIndex(p => p.Id == itemId);
    state.VehiclesType.splice(ItemIndex, 1);
  },

  UPDATE_VehiclesType(state, item) {
    debugger;
    const Index = state.VehiclesTypes.findIndex(p => p.Id == item.Id);
    Object.assign(state.VehiclesTypes[Index], item);
  }

};
